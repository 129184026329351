import React from 'react';
import HeroDiagonalCotizacion from '../components/HeroDiagonalCotizacion';
import Payments from '../components/Payments';
import Layout from '../templates/Layout';
import SEO from '../components/SEO';
import ContactForm from '../components/ContactForm';
import { CallTracking } from '../utilities/EventTracking';

const CotizacionPage = () => {
  return (
    <Layout>
      <SEO
        pageTitle="Estimados gratis"
        description="V&A Roofing, Inc. ofrece estimado gratis para el mejor precio de sellado de techo en Puerto Rico. Completa el formulario de cotización o llama al 787-597-6536."
      />
      <HeroDiagonalCotizacion />
      <div id="contact-form" className="container max-w-xl p-4 my-12 lg:my-16">
        <h2 className="mb-4 text-2xl font-bold text-center lg:text-4xl">
          Cotización gratuita
        </h2>
        <p className="text-lg">
          Completa el siguiente formulario de solicitud de cotización. Nos
          estaremos comunicando contigo en menos de 24 horas para coordinar una
          cita. También puedes llamarnos al{' '}
          <a
            href="tel:+17875976536"
            className="font-extrabold text-red-700"
            onClick={CallTracking}
          >
            787-597-6536
          </a>
          .
        </p>
        <ContactForm />
      </div>
      <Payments />
    </Layout>
  );
};

export default CotizacionPage;
