/* eslint-disable jsx-a11y/no-onchange */
import React, { useState } from 'react';
import { navigate } from 'gatsby-link';
import Button from '../components/Button';

function encode(data) {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
    .join('&');
}

const ContactForm = () => {
  const [state, setState] = useState({});

  const handleChange = e => {
    setState({
      'tipo-servicio': 'Residencial',
      ...state,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = e => {
    e.preventDefault();
    const form = e.target;
    fetch('/', {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: encode({
        'form-name': form.getAttribute('name'),
        ...state,
      }),
    })
      .then(() => navigate(form.getAttribute('action')))
      .catch(error => alert(error));
  };

  const handleFormReset = () => {
    setState({});
  };

  return (
    <div className="w-full max-w-xl mx-auto my-8">
      {/* <div className="mt-4 mb-8 text-gray-600">
        <span className="mr-1 text-red-800" aria-hidden="true">
          *
        </span>
        Todos los campos son requeridos.
      </div> */}
      <form
        name="contact-varoofing"
        method="POST"
        data-netlify="true"
        data-netlify-honeypot="bot-field"
        // data-netlify-recaptcha="true"
        action="/solicitud-enviada/"
        onSubmit={handleSubmit}
        className="px-4 pt-4 bg-white border border-gray-200 rounded-lg shadow-sm md:px-8 md:pt-6 md:pb-8 lg:mb-4"
      >
        <input type="hidden" name="form-name" value="contact-varoofing" />
        <p hidden>
          <label>
            Don’t fill this out: <input name="bot-field" />
          </label>
        </p>
        <div>
          <div className="mb-4">
            <label
              className="block mb-2 text-sm font-semibold text-gray-700"
              htmlFor="name"
            >
              Nombre
              {/* <span className="ml-1 text-red-800" aria-hidden="true">
                *
              </span> */}
            </label>
            <input
              className={`py-3 px-4 block w-full shadow-sm focus:ring-red-200 focus:border-red-200 border-gray-300 rounded-md`}
              type="text"
              name="name"
              id="name"
              aria-describedby="name-required-message"
              required
              aria-required="true"
              onChange={handleChange}
            />
          </div>
          <div id="name-required-message" className="hidden visuallyhidden">
            Favor de proveer su nombre.
          </div>

          <div className="mb-4">
            <label
              className="block mb-2 text-sm font-semibold text-gray-700"
              htmlFor="email"
            >
              Correo electrónico
              {/* <span className="ml-1 text-red-800" aria-hidden="true">
                *
              </span> */}
            </label>
            <input
              className="block w-full px-4 py-3 border-gray-300 rounded-md shadow-sm focus:ring-red-200 focus:border-red-200"
              type="email"
              name="email"
              id="email"
              aria-describedby="email-required-message"
              required
              aria-required="true"
              onChange={handleChange}
            />
          </div>
          <div id="email-required-message" className="hidden visuallyhidden">
            Favor de proveer su correo electrónico.
          </div>
          <div className="mb-4">
            <label
              className="block mb-2 text-sm font-semibold text-gray-700"
              htmlFor="phone"
            >
              Teléfono
              {/* <span className="ml-1 text-red-800" aria-hidden="true">
                *
              </span> */}
            </label>
            <input
              className="block w-full px-4 py-3 border-gray-300 rounded-md shadow-sm focus:ring-red-200 focus:border-red-200"
              type="tel"
              name="phone"
              id="phone"
              aria-describedby="phone-required-message"
              required
              aria-required="true"
              onChange={handleChange}
            />
          </div>
          <div id="phone-required-message" className="hidden visuallyhidden">
            Favor de proveer su número telefónico.
          </div>

          <div className="mb-4">
            <span
              className="block mb-2 text-sm font-semibold text-gray-700"
              htmlFor="tipo-servicio"
            >
              Tipo de Servicio
              {/* <span className="ml-1 text-red-800" aria-hidden="true">
                *
              </span> */}
            </span>
            <select
              className="block w-full px-4 py-3 border-gray-300 rounded-md shadow-sm focus:ring-red-200 focus:border-red-200"
              name="tipo-servicio"
              id="tipo-servicio"
              aria-describedby="tipo-servicio-required-message"
              required
              aria-required="true"
              onChange={handleChange}
            >
              <option value="Residencial">Residencial</option>
              <option value="Comercial">Comercial</option>
              <option value="Industrial">Industrial</option>
            </select>
          </div>
          <div
            id="tipo-servicio-required-message"
            className="hidden visuallyhidden"
          >
            Favor de proveer tipo de servicio.
          </div>

          <div className="mb-4">
            <label
              className="block mb-2 text-sm font-semibold text-gray-700"
              htmlFor="pueblo"
            >
              Pueblo
              {/* <span className="ml-1 text-red-800" aria-hidden="true">
                *
              </span> */}
            </label>
            <input
              className={`py-3 px-4 block w-full shadow-sm focus:ring-red-200 focus:border-red-200 border-gray-300 rounded-md`}
              type="text"
              name="pueblo"
              id="pueblo"
              aria-describedby="pueblo-required-message"
              required
              aria-required="true"
              onChange={handleChange}
            />
          </div>
          <div id="pueblo-required-message" className="hidden visuallyhidden">
            Favor de proveer el pueblo donde interesa el servicio.
          </div>

          <div>
            <label
              className="block mb-2 text-sm font-semibold text-gray-700"
              htmlFor="message"
            >
              Mensaje
              <span
                className="px-2 py-1 ml-2 text-xs text-gray-500 bg-gray-200 rounded"
                aria-hidden="true"
              >
                opcional
              </span>
            </label>
            <textarea
              className="block w-full px-4 py-3 border-gray-300 rounded-md shadow-sm focus:ring-red-200 focus:border-red-200"
              name="message"
              rows="4"
              id="message"
              aria-describedby="required-message"
              required
              aria-required="true"
              onChange={handleChange}
            ></textarea>
            <div className="hidden visuallyhidden">
              Favor de escribir su mensaje
            </div>
          </div>
        </div>
        <div data-netlify-recaptcha="true"></div>
        <div>
          <Button
            buttonText="Enviar solicitud"
            type="submit"
            additionalClassName="mr-4 mt-6 mb-4 md:mb-0 tracking-wide"
          />
          <button
            className="pt-1 pb-6 text-sm tracking-wide text-gray-800 uppercase cursor-pointer md:py-0 hover:text-gray-500 focus:outline-none focus:shadow-outline"
            type="reset"
            onClick={handleFormReset}
          >
            Borrar
          </button>
        </div>
      </form>
    </div>
  );
};

export default ContactForm;
