import React from 'react';
import Img from 'gatsby-image';
import { useImageQuery } from '../hooks/useImageQuery';
import { useSiteMetadata } from '../hooks/useSiteMetadata';
import { AnchorLink } from 'gatsby-plugin-anchor-links';

const HeroDiagonal = () => {
  const { homeHero } = useImageQuery();
  const {
    primaryColor,
    // primaryHover,
    primaryBg,
    primaryBgHover,
  } = useSiteMetadata();

  return (
    <section className="mx-4 mt-2 mb-16 md:mb-20">
      <div className="container relative px-4 py-5 border border-gray-200 rounded-lg sm:px-12 sm:py-12 lg:py-0 bg-stripes lg:px-0">
        {/* wrp */}
        <div className="grid grid-cols-1 lg:grid-cols-2">
          {/* start of message wrapper */}
          <div className="lg:row-span-2">
            {/* mensaje */}
            <div className="flex flex-col content-center pb-10 space-y-4 sm:space-y-2 md:space-y-6 2xl:space-y-12 lg:px-12 lg:py-16 lg:text-left">
              {/* level 2 */}
              <div>
                <h1 className="text-3xl font-extrabold tracking-tight text-gray-900 sm:text-4xl sm:leading-snug md:text-5xl">
                  ¿Necesitas <span className={`${primaryColor}`}>cotizar</span>{' '}
                  un sellado de techo?
                </h1>
              </div>
              <div>
                {/* next level */}
                <p className="mt-3 text-base text-gray-700 tex-left sm:mt-5 sm:text-lg sm:mx-auto md:mt-5 md:text-xl lg:text-2xl lg:leading-8 lg:mx-0">
                  <span className={`${primaryColor}`}>
                    <strong>Estimados Gratis</strong>
                  </span>{' '}
                  y servicio en{' '}
                  <span className={`${primaryColor}`}>
                    <strong>toda la Isla</strong>
                  </span>
                  .
                </p>
              </div>

              {/* buttons */}
              <div className="lg:flex sm:justify-center lg:justify-start">
                <div className="text-xs rounded-md shadow">
                  <AnchorLink
                    // onClick={trackResidentialHeroButton}
                    to="/cotizacion#contact-form"
                    role="button"
                    title="Estimado gratis"
                    className={`w-full text-center flex items-center justify-center px-8 py-4 border border-transparent text-base leading-6 font-medium rounded-md text-white ${primaryBg} hover:${primaryBgHover} focus:outline-none focus:shadow-outline transition duration-150 ease-in-out lg:py-4 lg:text-lg lg:px-10 uppercase font-bold tracking-wide`}
                  >
                    Completar solicitud
                  </AnchorLink>
                </div>
              </div>
              {/* end of button */}
            </div>
          </div>
          {/* end of message wrapper */}
          <div className="relative w-full lg:absolute lg:inset-y-0 lg:right-0 lg:w-1/2 lg:h-full">
            <Img
              fluid={homeHero.childImageSharp.fluid}
              alt="Sellado de techo"
              className="absolute inset-0 object-cover w-full h-full rounded-lg lg:rounded-l-none"
            />
            <div
              className="absolute inset-0 bg-gray-400 rounded-lg lg:rounded-l-none"
              style={{ mixBlendMode: 'multiply' }}
            ></div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default HeroDiagonal;
