import React from 'react';
import { useSiteMetadata } from '../hooks/useSiteMetadata';

const Button = ({ buttonText, type, additionalClassName }) => {
  const { primaryBg, primaryBgHover } = useSiteMetadata();

  return (
    <button
      className={`${primaryBg} hover:${primaryBgHover} focus:outline-none focus:shadow-outline min-w-1/4 px-8 py-4 text-white rounded-md inline-block mt-4 text-center font-bold uppercase transition-colors duration-500 ease-in-out ${additionalClassName}`}
      type={type}
    >
      {buttonText}
    </button>
  );
};

export default Button;
